<template>
  <div
    v-if="isDirectOrderDataLoading"
    class="d-flex justify-content-center mb-1"
    style="padding-top: 30vh"
  >
    <b-spinner class="align-middle text-info" />
  </div>
  <div v-else>
    <b-breadcrumb class="mb-2 ">
            <b-breadcrumb-item to="/orders/business">Packages</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link
          :to="{
            name: 'business-commande',
            params: { id: this.$route.query.packageId } 
           
          }"
          >Commandes</router-link
        >
      </b-breadcrumb-item>
            <b-breadcrumb-item>
             <router-link :to="{ name: 'admin-orders-business-recurring-details', params: { id: this.$route.params.id }, query: {packageId: this.$route.query.packageId} }">Détails</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>Modifier</b-breadcrumb-item>
    </b-breadcrumb>
    <b-card>
      <validation-observer #default="{}" ref="modifyOrder">
        <!-- body -->
        <b-form id="new-client-form" @submit.prevent="applyModifyOrder()">
          <b-row>

            <!-- Search employee -->
            <!-- <b-col md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Recherche ou gestion d'employé"
                rules="required"
              >
                <b-form-radio-group
                  v-model="payload.search_employee"
                  :options="typeOptions"
                  :state="errors.length > 0 ? false : null"
                  value-field="item"
                  text-field="name"
                  class="demo-inline-spacing custom-control-success"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
 -->
            <!-- Employee firstname -->
            <!-- <b-col v-if="payload.search_employee === 0" md="6">
              <b-form-group label="Prénom de l'employé" label-for="firstname">
                <validation-provider
                  #default="{ errors }"
                  name=" prenom & nom "
                  rules="required"
                >
                  <b-form-input
                    id="firstname"
                    v-model="payload.employee_first_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le Prénom l'employé"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <!-- Employee phone number -->
            <!-- <b-col v-if="payload.search_employee === 0" md="6">
              <validation-provider
                #default="{ errors }"
                name="Numéro de téléphone"
                rules="required"
              >
                <b-form-group label="Numéro de téléphone" label-for="phone">
                  <b-input-group>
                    <b-input-group-prepend
                      is-text
                      :class="errors[0] ? 'border-danger' : ''"
                    >
                      BJ (+229)
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="phone"
                      class="form-control"
                      :class="errors[0] ? 'border-danger' : ''"
                      :raw="false"
                      :options="options.phone"
                      placeholder="12 34 56 78"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col> -->

            <!-- Requested service -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Service recherché"
                rules="required"
              >
               <b-form-group label="Service recherché" label-for="services">
                  <v-select id="role" v-model="payload.recurring_service_id" :reduce="(service) => service.id" label="name"
                    :options="recurringServicesOption" :state="errors.length > 0 ? false : null">
                    <template v-slot:no-options>
                      <b-spinner v-if="isServicesRecurringWithoutPaginationLoading"
                        style="width: 2.5rem; height: 2.5rem" class="align-middle text-info" />

                      <span v-else class="mt-1 font-medium-1">Ce service n'existe pas</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Budget -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="BudjetParEmployee"
                rules="required"
              >
                <b-form-group label="Budget par employé" label-for="brut_salary">
                  <b-form-input
                    id="brut_salary"
                    v-model="payload.brut_salary"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le budget par employé"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Intervention frequency -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Fréquence d'intervention"
                rules="required"
              >
                <b-form-group
                  label="Fréquence d'intervention"
                  label-for="intervention-frequency"
                >
                  <b-form-select
                    id="intervention-frequency"
                    v-model="payload.intervention_frequency"
                    :options="interventionFrequencyOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
             <!-- Nombre d'employés -->
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Nombre d'employé" rules="required">
                <b-form-group label="Nombre d'employé" label-for="number_of_employees">
                  <b-form-input id="number_of_employees" v-model="payload.number_of_employees" type="number"
                    :state="errors.length > 0 ? false : null" placeholder="Entrez le nombre d'employé" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
             <!--Client's employees -->
            
             <!-- CNSS -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Cnss"
                rules="required"
              >
                <b-form-group
                  label="Déclarer à la cnss"
                  label-for="cnss"
                >
                  <b-form-select
                    id="cnss"
                    v-model="payload.cnss"
                    :options="cnssOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Address -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="address"
                rules="required"
              >
                <b-form-group label="Lieu d'intervention" label-for="address">
                  <b-form-input
                    id="address"
                    v-model="payload.address"
                    placeholder="Lieu d'intervention"
                    :state="errors.length > 0 ? false : null"
                    autocomplete="off"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Other needs -->
            <b-col md="12">
                <validation-provider #default="{ errors }" name="contrat" rules="">
                  <b-form-group label="Contrat de prestation" label-for="contrat">
                    <b-form-file
                      v-model="payload.contract"
                      accept=".pdf"
                      placeholder="Sélectionnez un fichier PDF"
                      :state="Boolean(pdfFile)"
                      >
                    </b-form-file>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

            <!-- submit and reset -->
            <b-col class="mt-2" md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="isModifyOrderLoading"
              >
                <div v-if="isModifyOrderLoading">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span v-else class="text-nowrap font-medium-1">Modifier</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BSpinner,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormFile
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.bj";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Cleave,
    BSpinner,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormFile
  },

  directives: {
    Ripple,
  },

  data() {
    return {

      isModifyOrderLoading: false,

      // isRecurringServicesLoading: true,

      required,

      recurringServicesOption: [],

      directOrder: null,

      typeOptions: [
        { item: 1, name: "À la recherche d'employé" },
        // { item: 0, name: "Confier la gestion d'employé" },
      ],

      budgetOptions: [
        {
          value: "Entre 15000 & 50000",
          text: "Entre 15000 & 50000",
        },
        {
          value: "Entre 50000 & 80000",
          text: "Entre 50000 & 80000",
        },
        {
          value: "Entre 80000 & 120000",
          text: "Entre 80000 & 120000",
        },
        {
          value: "Entre 120000 & plus",
          text: "Entre 120000 & plus",
        },
      ],
       cnssOptions: [
        {
          value: 1,
          text: 'Oui',
        },
        {
          value: 0,
          text: 'Non',
        },
      ],
      interventionFrequencyOptions: [
        {
          value: 7,
          text: "Tous les jours",
        },
        {
          value: 1,
          text: "1 fois par semaine",
        },
        {
          value: 2,
          text: "2 fois par semaine",
        },
        {
          value: 3,
          text: "3 fois par semaine",
        },
        {
          value: 4,
          text: "4 fois par semaine",
        },
        {
          value: 5,
          text: "5 fois par semaine",
        },
        {
          value: 6,
          text: "6 fois par semaine",
        },
        
      ],

      // offerTypeOptions: [
      //   {
      //     value: 1,
      //     text: "Confort",
      //   },
      //   {
      //     value: 2,
      //     text: "Premium",
      //   },
      // ],

      frequency: "",
      other_intervention_frequency: "",
      employeeOptions: [],
      formEmployees: [],
      payload: {
        search_employee: null,
        recurring_service_id: null,
        brut_salary: "",
        intervention_frequency: "",
        address: "",
        cnss: "",
        number_of_employees: '',
        user_id: "",
        contrat: "",
        auto_assignment: 0
      },
      phone: "",

      options: {
        phone: {
          phone: true,
          phoneRegionCode: "BJ",
        },
      },

      isDirectOrderDataLoading: true,

      isFrequencyInterventionValuesMatched: false,
    };
  },

  computed: {
    ...mapGetters('services', ['getRecurringServices', 'getRecurringServicesWithoutPagination']),
    ...mapGetters(["isRecurringServicesLoading"]),
    ...mapGetters("orders", ["getDirectOrder"]),
    ...mapGetters("customers", ["getNewCustomer"]),
    ...mapGetters(['isServicesRecurringWithoutPaginationLoading']),
  },

  watch: {
   isServicesRecurringWithoutPaginationLoading(val) {
      if (val === false) {
        this.getRecurringServicesWithoutPagination.forEach(element => {
          this.recurringServicesOption.push({
            id: element.id,
            name: element.name,
          })
        })
      }
    },
    // getNewCustomer(val) {
    //   if (val !== null) {
    //     this.clientData = val;
    //   }
    // },
  },

  created() {
    try{
       if (this.getDirectOrder !== null) {
      this.directOrder = this.getDirectOrder;
      console.log('DIRECT OREDER',this.directOrder)
       this.isDirectOrderDataLoading = false;
      this.payload = {
        search_employee: this.directOrder.search_employee ? 1 : 0,
        recurring_service_id: this.directOrder.recurring_service_id,
        brut_salary: this.directOrder.brut_salary,
        intervention_frequency: this.directOrder.intervention_frequency,
        address: this.directOrder.address,
        cnss: this.directOrder.cnss ? 1 : 0,
        number_of_employees: this.directOrder.number_of_employees,
        user_id: this.directOrder.package.user.id,
        auto_assignment: this.directOrder.auto_assignment ? 1 : 0,
      };
      
     
    } else {
      this.applyGetBusinessOrderData();
    }
    }
    catch(error){
     console.log(error)

    }
   
    // this.getClientEmployees()
  },

  mounted() {
     if (this.getRecurringServicesWithoutPagination.length != 0) {
      this.getRecurringServicesWithoutPagination.forEach(element => {
        this.recurringServicesOption.push({
          id: element.id,
          name: element.name,
        })
      })
      
    }
    this.configProAddressField();

     if (this.formEmployees.length != 0) {
      this.formEmployees.forEach(element => {
        this.employeeOptions.push({
          id: element.id,
          name: element.full_name,
        })
      })
    }
    console.log(this.employeeOptions)
  },

  methods: {
    ...mapActions("orders", [
      // "modifyDirectOrderAction",
      // "getDirectOrderAction",
    ]),

    ...mapActions("packages", [
      "modifyDirectOrderAction",
      "getDirectOrderAction",
    ]),

    ...mapActions("orders", [
      "modifyBusinessOrderAction",
      "getBusinessOrderAction",
      "getBusinessOrdersAction",
    ]),

    ...mapActions("services", ["getRecurringServicesAction"]),

    ...mapActions("customers", ["getCustomersAction", "getCustomersEmployeesWithoutPaginationAction"]),

     getClientEmployees() {
      this.getCustomersEmployeesWithoutPaginationAction()
        .then(response => {
        response.data.forEach(element => {
            this.employeeOptions.push({
              id: element.id,
              name: element.full_name,
            })
          });
          console.log('Clients', this.employeeOptions)
          
        })
        .catch(error => {
        console.log(error)
      })
    },

    configProAddressField() {
      const input = document.getElementById("address");
      const options = {
        types: ["establishment"],
        componentRestrictions: { country: ["BJ"] },
      };
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(input, options);

      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        this.payload.address = place.name;
      });
    },

    applyGetBusinessOrders() {
      this.isReLoadDataRecurringOrders = true
      this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', true)

      this.getBusinessOrdersAction()
        .then(response => {
          this.isReLoadDataRecurringOrders = false
          this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
        })
        .catch(error => {
          this.isReLoadDataRecurringOrders = false
          this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
        })
    },

    applyGetBusinessOrderData() {
      const directOrderId = this.$route.params.id;
      this.getBusinessOrderAction(directOrderId)
        .then((response) => {
          this.isDirectOrderDataLoading = false;
          console.log(response.data);
          this.payload = {
            recurring_service_id: response.data.recurring_service_id,
            brut_salary: response.data.brut_salary,
            intervention_frequency: response.data.intervention_frequency,
            address: response.data.address,
            cnss: response.data.cnss ? 1 : 0,
            number_of_employees: response.data.number_of_employees,
            user_id: response.data.user_id,
            auto_assignment: response.data.auto_assignment ? 1 : 0,
          };
          
        })
        .catch((error) => {
          this.isDirectOrderDataLoading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "TriangleIcon",
                text: error.response.message,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },
    resolveStatusText(element) {
            if (
                element.status === 0
            ) {
                element.setStatus = "En attente d'affectation";
                return element;
            }

            if (element.status === 1) {
                element.setStatus = "Employé affecté";

                return element;
            }
            if (element.status === 2) {
                element.setStatus = "Actif";
                return element;
            }
            if (element.status === -1) {
                element.setStatus = "Résilié";

                return element;
            }

        },

    putRecurringOrdersInStoreAction(directOrder) {
      this.$store.commit("orders/SET_DIRECT_ORDER", directOrder);
      this.$router.push({
        name: "admin-orders-recurring-details",
        params: { id: this.$route.params.id },
        query: {packageId: this.$route.query.packageId}
      });
    },

    applyModifyOrder() {
      this.$refs.modifyOrder.validate().then((success) => {
        if (success) {
          this.isModifyOrderLoading = true;
         

          
          
          
          // if (this.payload.search_employee === 0) {
          //   this.payload.employee_phone_number = `229${this.phone.replace(
          //     /\s/g,
          //     ""
          //   )}`;
          // }
          this.modifyBusinessOrderAction({
            id: this.$route.params.id,
            payload: this.payload,
          })
            .then((response) => {
              console.log('reponseeee',response)
              this.isModifyOrderLoading = false;
              this.directOrder = response.data;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: response.message,
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              
              this.getBusinessOrderAction(this.$route.params.id)
              .then((response) => {
                this.isPageLoading = false;
                console.log("Orderssss", response.data);
                this.directOrder = response.data;
                this.directOrder = this.resolveStatusText(this.directOrder);
                
              })
              this.$router.push(`/orders/business/recurring/${this.$route.params.id}/details?packageId=${this.$route.query.packageId}`)
              this.applyGetBusinessOrders();
              
            })
            .catch((error) => {
              console.log(error)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Erreur",
                    icon: "TriangleIcon",
                    text: error.response.data.message,
                    variant: "danger",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.isModifyOrderLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
